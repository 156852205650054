import React, { Component, Fragment } from "react";
import { Form, Input, Grid, Button, Dropdown } from "semantic-ui-react";

class GetPackages extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        queryType: "Account ID",
        AccountId: "",
        PackageId: "",
        itemCode: 1, 
      },
      accountIdErr: "",
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    const { formData } = this.state;
    formData[name] = value;
    this.setState({ formData, accountIdErr: "" });
  };

  handleDropdownChange = (e, { value }) => {
    const { formData } = this.state;
    formData.itemCode = value;
    this.setState({ formData });
  };

  isValidated = () => {
    const { formData } = this.state;

    if (!formData.AccountId.trim()) {
      this.setState({ accountIdErr: "Account ID is required" });
      return false;
    }
    return true;
  };

  search = () => {
    const { formData } = this.state;
    const { getPackages, isFetching } = this.props;
    if (isFetching) return;

    if (this.isValidated()) {
      const params = {};

      params.accountId = formData.AccountId.trim();
      params.itemCode = formData.itemCode;
      getPackages(params);
    }
  };

  render() {
    const { formData } = this.state;
    const { isFetching, back } = this.props;

    const itemCodeOptions = [
      { key: "changePlan", text: "Change Plan", value: 2 },
      { key: "refill", text: "Refill", value: 1 },
      { key: "renewal", text: "Renewal", value: 0 },
    ];

    return (
      <Fragment>
        <Form loading={isFetching}>
          <Form.Field>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={3} tablet={16} mobile={16}>
                  <label className="label-bold">User ID:</label>
                </Grid.Column>
                <Grid.Column computer={13} tablet={16} mobile={16}>
                  <Input
                    name="AccountId"
                    value={formData.AccountId}
                    onChange={this.handleInputChange}
                    placeholder="User ID"
                    autoComplete="off"
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column computer={3} tablet={16} mobile={16}>
                  <label className="label-bold">Item Code:</label>
                </Grid.Column>
                <Grid.Column computer={13} tablet={16} mobile={16}>
                  <Dropdown
                    placeholder="Select Item Code"
                    selection
                    options={itemCodeOptions}
                    value={formData.itemCode}
                    onChange={this.handleDropdownChange}
                    style={{ minWidth: "150px" }} // Ensure the dropdown has a minimum width
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
        </Form>

        <Grid>
          <Grid.Row></Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                labelPosition="left"
                floated="right"
                color="blue"
                content="Search"
                icon="search"
                loading={isFetching}
                disabled={isFetching}
                onClick={this.search}
              />
              <Button
                basic
                labelPosition="left"
                floated="left"
                content="Back"
                icon="arrow left"
                onClick={back}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default GetPackages;
