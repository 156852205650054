import axios from 'axios';

const request = {};

const CancelToken = axios.CancelToken;
let cancel;

request.make = (options) => {
    const _options = options || {};
    const payload = _options.data || {};
    const headers = _options.headers || {};

    headers['Content-Type'] = 'application/json';

    return new Promise((resolve, reject) => { 
        axios({
            baseURL: _options.baseUrl,
            method: _options.method,
            url: _options.url,
            headers: headers,
            data: payload,
            cancelToken: new CancelToken(c => {
                cancel = c;
            }),
            validateStatus: status => {
                return status < 400;
            },
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            if (err.response) {
                reject(err.response)
            }
            
            if (err.request) {
                reject(err.request);
            }
            reject(err);
        })
    })
}

request.cancel = () => {
    cancel && cancel();
}

export default request;
