import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';

import { ToastProvider, DefaultToast } from 'react-toast-notifications';
// import 'semantic-ui-css/semantic.min.css';
import './index.css';

import App from './module/app';

const ToastWrapper = ({ children, ...props }) => (
    <DefaultToast {...props} className="custom_toast">
        {children}
    </DefaultToast>
)
ReactDOM.render(
    <ToastProvider components={{ Toast: ToastWrapper }}>
        <App />
    </ToastProvider>,
document.getElementById('root'));
