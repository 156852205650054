import request from "./request";
import { errorProcessor, getQueryStringParameter } from "./utils";

const BASE_URL =  process.env.REACT_APP_BASE_URL;


export function getPackages(payload) {
  const options = {
    baseUrl: BASE_URL,
    url: "/customers",
    method: "POST",
     data: JSON.stringify(payload),
  };
  return request
  .make(options)
  .then((res) => {
    console.log("Response:", res); 
    return res;
  })
    .catch(errorProcessor);
}

export function makePayment(payload) {
  const options = {
    baseUrl: BASE_URL,
    url: "transactionlogs",
    method: "POST",
    data: payload,
  };
  return request
    .make(options)
    .then((res) => res)
    .catch(errorProcessor);
}

export function submitChangePlan(payload) {
  console.log(JSON.stringify(payload))
  const options = {
    baseUrl: BASE_URL,
    url: "/customers/changeplan", 
    method: "POST",
    data: JSON.stringify(payload),
  };
  return request
    .make(options)
    .then((res) => {
      console.log("Change Plan Response:", res);
      return res;
    })
    .catch(errorProcessor);
}
