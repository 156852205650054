import React from "react";
import { withToastManager } from "react-toast-notifications";
import {
  Container,
  Grid,
  Segment,
  Header,
  Image,
  Divider,
  Card,
  Button,
  Icon,
  Input,
} from "semantic-ui-react";
import { getPackages } from "./service";
import { makePayment } from "./service";
import { submitChangePlan } from "./service";
import GetPackages from "./get-package";
import spectranet from "../spectranet.png";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      requestData: null,
      packages: null,
      isFetching: false,
      show: false,
      userEnteredAmount: "", 
      showChangePlan: false, 
      changePlans: null,
    };
  }

  getPackages = (data) => {
  this.setState({ isFetching: true, packages: null, showChangePlan: false }, () => {
    getPackages(data)
      .then((response) => {
        if (response.message === "success") {
          this.setState({
            requestData: {
              ...data,
              accountId: response.data.userID,
            },
            packages: response,
            isFetching: false,
            show: true,
            showChangePlan: data.itemCode === 2, // This handles showing the change plan screen
            showSecondDiv: data.itemCode === 1, // Add this condition to track when itemCode is 1
            changePlans: data.itemCode === 2 ? response.data.changePlans : null,
            
          });
        } else {
          this.handleError(response.responseDescription, "isFetching");
        }
      })
      .catch((err) => this.handleError(err, "isFetching"));
  });
  const { requestData } = this.state;
};



  handleBack = () => {
    this.setState({
      show: false,
      showChangePlan: false, 
    });
  };

  handleSubmit = () => {
    const { userEnteredAmount, requestData, packages } = this.state;
    const paymentDetails = {
      accountId: requestData ? requestData.accountId : "",
      firstName: (packages && packages.data && packages.data.customerName) ? packages.data.customerName.split(" ")[0] : "",
      lastName: (packages && packages.data && packages.data.customerName) ? packages.data.customerName.split(" ")[1] : "",
      activateTime: requestData ? requestData.activateTime : "0",
      package: [{
        packageId: (requestData && requestData.packageId) ? requestData.packageId : "",
        packageNo: (requestData && requestData.packageNo) ? requestData.packageNo : "",
        packageName: (requestData && requestData.packageName) ? requestData.packageName : "",
        itemCode: (requestData && requestData.itemCode) ? String(requestData.itemCode) : "",
        amountDue: parseFloat(userEnteredAmount) || (packages && packages.data && packages.data.amount ? parseFloat(packages.data.amount) : 0)
      }],
    };

    this.setState({ isFetching: true });

    makePayment(paymentDetails)
      .then(() => {
        this.setState({ isFetching: false });
        this.props.toastManager.add("Payment Successful", { appearance: "success" });
        this.handleBack();
      })
      .catch(() => {
        this.setState({ isFetching: false });
        this.props.toastManager.add("Payment Failed", { appearance: "error" });
      });
  };
  handleChangePlanSubmit = () => {
    const { changePlans,requestData,packages } = this.state;
    console.log("Request Data: ", requestData);
    // Here, you can define the submission logic
    if (!changePlans || changePlans.length === 0) {
      console.error("No plans available for submission!");
      return;
    }
  
    
    const selectedPlan = changePlans[0]; 
    console.log("Request Data: ", requestData);
    const payload = {
      
      name: selectedPlan.name,
      totalAmount: selectedPlan.totalCostWithTax,
      userID : requestData.accountId,
      otherDetails:{
        packageID: selectedPlan.packageId,
        firstName : packages.data.customerName,
        lastName: packages.data.customerName.split(" ")[1]

      },
      hash: packages.data.hash
    
    };
  
   
  
  
    submitChangePlan(payload)
      .then((response) => {
        this.setState({ isFetching: false });
        this.props.toastManager.add("Plan Changed Successful", { appearance: "success" });
        this.handleBack();
      })
      .catch((error) => {
        this.setState({ isFetching: false });
        this.props.toastManager.add("Payment Failed", { appearance: "error" });
      });
  };
  
  renderChangePlan = () => {
    const { changePlans } = this.state;
    return (
      <div className="change-plan">
        <h3>Available Change Plans</h3>
        {changePlans && changePlans.map((plan) => (
          <Card key={plan.packageId} fluid>
            <Card.Content>
              <Card.Header>{plan.name}</Card.Header>
              <Card.Description>{plan.description}</Card.Description>
              <Card.Meta>Total Cost: {plan.totalCostWithTax}</Card.Meta>
            </Card.Content>
          </Card> 
        ))}
  
        {/* Buttons */}
        <div style={{ marginTop: "20px" }}>
          <Button basic floated="left" content="Back" icon="arrow left" onClick={this.handleBack} />
          
          {/* Submit button */}
          <Button
            floated="right"
            color="teal"
            content="Submit"
            icon="check"
            onClick={this.handleChangePlanSubmit} // Trigger the submit action
          />
        </div>
      </div>
    );
  };
  
  render() {
    const { packages, isFetching, show, showChangePlan, showSecondDiv, userEnteredAmount } = this.state;
    const packageData = (packages && packages.data) || {};
  
    return (
      <Container className="main-container">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <Segment.Group raised>
                <Segment clearing className="logo-header">
                  <Header floated="right">
                    <Image src={spectranet} size="large" />
                  </Header>
                </Segment>
  
                <Segment.Group raised>
                  <Segment padded="very">
                    {/* Render GetPackages component when show is false */}
                    {!show && (
                      <GetPackages
                        getPackages={this.getPackages}
                        isFetching={isFetching}
                      />
                    )}
  
                    {/* Render second div only when showSecondDiv is true (itemCode === 1) */}
                    {show && showSecondDiv && (
                      <div>
                        <h3>Enter Amount</h3>
                        <Input
                          placeholder="Enter Amount"
                          value={userEnteredAmount}
                          onChange={(e) => this.setState({ userEnteredAmount: e.target.value })}
                        />
  
                        <Grid style={{ marginTop: "20px" }}>
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <Button basic floated="left" content="Back" icon="arrow left" onClick={this.handleBack} />
                              <Button
                                floated="right"
                                color="green"
                                content="Submit"
                                icon="send"
                                loading={isFetching}
                                disabled={isFetching}
                                onClick={this.handleSubmit}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    )}
  
                    {/* Render first div when showSecondDiv is false (itemCode !== 1) */}
                    {show && packageData && !showSecondDiv && !showChangePlan && (
                      <div className="make-payment">
                        {packageData.amount ? (
                          <Segment
                            raised
                            padded="very"
                            className="package-details-segment"
                          >
                            <Card fluid>
                              <Card.Content>
                                <Image
                                  floated="right"
                                  size="mini"
                                  src={spectranet}
                                  circular
                                  style={{ backgroundColor: "#f5f5f5", padding: "10px" }}
                                />
                                <Card.Header>
                                  <Header as="h2" className="package-header">
                                    <Icon name="box" color="teal" />
                                    {packageData.planName || "Plan Details"}
                                  </Header>
                                </Card.Header>
                                <Card.Meta>
                                  <span className="user-id">
                                    <Icon name="user" color="blue" />
                                    User ID: {packageData.userID}
                                  </span>
                                </Card.Meta>
                                <Card.Description>
                                  <p className="plan-description">
                                    <Icon name="info circle" color="green" />
                                    {packageData.planDesc || "No description available"}
                                  </p>
                                  <p className="amount-detail">
                                    <Icon name="money" color="orange" />
                                    Amount: {packageData.amount || "N/A"}
                                  </p>
                                </Card.Description>
                              </Card.Content>
                              <Card.Content extra>
                                <Icon name="user circle" color="grey" />
                                Customer Name: {packageData.customerName}
                              </Card.Content>
                            </Card>
  
                            <Grid style={{ marginTop: "20px" }}>
                              <Grid.Row>
                                <Grid.Column width={16}>
                                  <Button basic floated="left" content="Back" icon="arrow left" onClick={this.handleBack} />
                                  <Button
                                    floated="right"
                                    color="green"
                                    content="Submit"
                                    icon="send"
                                    loading={isFetching}
                                    disabled={isFetching}
                                    onClick={this.handleSubmit}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        ) : null}
                      </div>
                    )}
  
                    {/* Render change plan screen when showChangePlan is true */}
                    {show && showChangePlan && this.renderChangePlan()}
                  </Segment>
                </Segment.Group>
  
                <Divider hidden />
                <Divider hidden />
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
 
}

export default withToastManager(App);
