
export function getQueryStringParameter(paramObj){
    let param ;

    for (let key of Object.keys(paramObj)){
        param = key + '=' + paramObj[key];
    }
    
    return `?${param}`;
}

export function errorProcessor(response) {
    const errMessage = err => {
        const customMsg = 'Something went wrong. Please try again';

        if (typeof err === 'object') {
            if (err.data) {
                if (typeof err.data === 'object') {
                    if (err.data.errors) {
                        const arr = err.data.errors;
                        if (Array.isArray(arr)) {
                            return arr.map(value => value.message).join(';');
                        }
                    }
                    return (err.data.description || err.data.responseDescription || err.data.responseMessage || customMsg);
                }
            }

            if (err.statusText) {
                return err.statusText;
            }
        }
        return typeof err === 'string' ? err : customMsg;
    }

    throw new Error(errMessage(response)).toString();
}
